<template>
    <div class="flex flex-col">
        <warning
            :icon="$icons.inTurn"
            :title="$t('error.warning')"
            :content="$t('error.selectAccountWarning')"
            v-if="!validAccount"
        />
        <warning
            :icon="$icons.inTurn"
            :title="$t('error.warning')"
            :content="$t('error.noInTurn')"
            :show-back="true"
            :show-home="true"
            v-else-if="!contextAccount.in_turn"
        />
        <manage-in-turn :class="{ loading: isLoading }" v-else />
    </div>
</template>

<script>
    import { get } from 'lodash-es';

    import { mapState } from 'pinia';
    import ManageInTurn from '../../components/in_turn/ManageInTurn.vue';
    import Warning from '../../components/Warning.vue';
    import TrackView from '../../mixins/TrackView.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            ManageInTurn,
            Warning,
        },

        mixins: [TrackView],

        computed: {
            ...mapState(useContextStore, {
                contextChildAccount: 'contextChildAccount',
                contextAccount: 'account',
            }),

            accountId() {
                if (!this.contextAccount) {
                    return null;
                }

                return this.contextAccount.id;
            },

            validAccount() {
                return get(this.contextChildAccount, 'id');
            },

            isLoading() {
                return this.$wait.is('fetching.inturns.users') || this.$wait.is('fetching.inturns');
            },
        },
    };
</script>
