<template>
    <div>
        <portal to="content-header-left">
            <activix-reload :loading="isLoading" @click="fetchInturnData" />
        </portal>

        <portal to="content-header-right">
            <router-link class="link-grey" :to="{ name: 'inTurn.show' }">
                <icon class="text-2xl mr-4 | lg:mr-0" name="regular/modern-tv-flat-screen" />
            </router-link>
        </portal>
        <div class="-mb-6" :class="{ loading: isLoading && !ready }">
            <template v-if="ready">
                <manage-in-turn-column
                    type="new"
                    :in-turn="inTurnNew"
                    :page-id="pageId"
                    :users="users"
                    @fetch-in-turns="fetchInTurns"
                />

                <manage-in-turn-column
                    type="used"
                    :in-turn="inTurnUsed"
                    :page-id="pageId"
                    :users="users"
                    @fetch-in-turns="fetchInTurns"
                />

                <manage-in-turn-column
                    type="financial"
                    :in-turn="inTurnFinancial"
                    :page-id="pageId"
                    :users="users"
                    @fetch-in-turns="fetchInTurns"
                    v-if="inTurns.length > 0 && inTurns[0].account.commercial_in_turn"
                />

                <manage-in-turn-column
                    type="deskManager"
                    :in-turn="inTurnDeskManager"
                    :page-id="pageId"
                    :users="users"
                    @fetch-in-turns="fetchInTurns"
                    v-if="inTurns.length > 0 && inTurns[0].account.desk_manager_in_turn"
                />
            </template>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import ManageInTurnColumn from './ManageInTurnColumn.vue';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            ManageInTurnColumn,
        },

        data() {
            return {
                inTurnNew: {},
                inTurnUsed: {},
                inTurnFinancial: {},
                inTurnDeskManager: {},
                inTurns: [],
                users: [],
                ready: false,
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            pageId() {
                return Math.floor(Math.random() * 1000000 + 1);
            },

            isLoading() {
                return this.$wait.is('fetching.inturns.users') || this.$wait.is('fetching.inturns') || !this.ready;
            },
        },

        watch: {
            inTurns() {
                this.initInTurns();
            },
            'contextAccount.id': {
                immediate: true,
                handler() {
                    this.fetchInturnData();
                },
            },
        },

        methods: {
            initInTurns() {
                for (let i = 0; i < this.inTurns.length; i++) {
                    if (this.inTurns[i].in_turn_type.name == 'new') {
                        this.inTurnNew = this.inTurns[i];
                    } else if (this.inTurns[i].in_turn_type.name == 'used') {
                        this.inTurnUsed = this.inTurns[i];
                    } else if (this.inTurns[i].in_turn_type.name == 'financial') {
                        this.inTurnFinancial = this.inTurns[i];
                    } else if (this.inTurns[i].in_turn_type.name == 'deskManager') {
                        this.inTurnDeskManager = this.inTurns[i];
                    }
                }

                this.ready = true;
            },

            async fetchInTurns(reload = false) {
                try {
                    if (reload) {
                        this.$wait.start('fetching.inturns');
                    }

                    const response = await this.$axios.get('v1/in-turn', {
                        params: {
                            accountId: this.contextAccount.id,
                            cache: now().timestamp(),
                        },
                    });

                    this.inTurns = response.data.inTurns;

                    if (reload) {
                        this.$wait.end('fetching.inturns');
                    }
                } catch (error) {
                    if (reload) {
                        this.$wait.end('fetching.inturns');
                    }
                }
            },

            async fetchUsers() {
                this.$wait.start('fetching.inturns.users');

                try {
                    const response = await this.$api.users.index({
                        filter: {
                            accountId: this.contextAccount.id,
                            active: true,
                        },
                        include: ['divisions', 'role', 'todayAppointments'],
                    });

                    this.users = response.filter(user => !user.hide_in_user_select).sort((a, b) => {
                        return `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`);
                    });

                    this.$wait.end('fetching.inturns.users');
                } catch (error) {
                    this.$wait.end('fetching.inturns.users');
                    this.$notify.error(this.$t('users.alerts.index.error'));

                    throw error;
                }
            },

            async fetchInturnData() {
                const requests = [];

                requests.push(this.fetchUsers());
                requests.push(this.fetchInTurns(true));

                await Promise.all(requests);
            },
        },

        created() {
            this.fetchInturnData();

            this.$eventBus.$on('update-in-turn', this.fetchInturnData);
        },

        beforeDestroy() {
            this.$eventBus.$off('update-in-turn', this.fetchInturnData);
        },
    };
</script>
