var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row | lg:flex mb-6"},[_c('div',{staticClass:"col-xs-12 col-md-6 | flex"},[_c('div',{staticClass:"box | flex flex-col"},[_c('div',{staticClass:"box-header | border-b flex items-center justify-between"},[_c('h4',{staticClass:"box-title"},[_vm._v(" "+_vm._s(_vm.$t('inturns.' + _vm.type + 'Vehicle'))+" ")]),_c('div',{staticClass:"flex items-center"},[(_vm.inTurn.users.length > 10)?_c('activix-tooltip',{attrs:{"content":_vm.$t('inturns.message_over_ten')}},[_c('icon',{staticClass:"text-grey-500",attrs:{"name":"regular/information-circle"}})],1):_vm._e(),(_vm.role == 'admin' && _vm.inTurn.account.gun_miss && _vm.inTurn.users.length > 0)?_c('activix-tooltip',{attrs:{"content":_vm.$t('inturns.resetGunMiss')}},[_c('icon',{staticClass:"link-grey-light text-lg ml-2",attrs:{"name":"regular/dislike-1"},on:{"click":_vm.resetGunMiss}})],1):_vm._e()],1)]),_c('div',{staticClass:"box-body | flex-1"},[_c('draggable',{staticClass:"list sortable scheduled-users | h-full",attrs:{"handle":".handle","options":{ group: _vm.type, draggable: '.draggable' }},on:{"add":_vm.addedUserToSchedule,"update":_vm.updateUserPosition,"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}},model:{value:(_vm.inTurnUsers),callback:function ($$v) {_vm.inTurnUsers=$$v},expression:"inTurnUsers"}},_vm._l((_vm.inTurnUsers),function(user,index){return _c('li',{key:user.id,staticClass:"flex items-center pl-1 py-0 cursor-default",class:{
                            draggable:
                                _vm.role == 'admin' ||
                                (user.id == _vm.authUser.id && !_vm.userDisabled(user)) ||
                                _vm.authUser.in_turn_edit,
                            'opacity-50':
                                _vm.role != 'admin' &&
                                (user.id != _vm.authUser.id || _vm.userDisabled(user)) &&
                                !_vm.authUser.in_turn_edit,
                        }},[_c('div',{staticClass:"handle | p-2 cursor-move flex items-center"},[_c('icon',{staticClass:"text-grey-600",attrs:{"slot":"icon","name":"regular/navigation-menu"},slot:"icon"})],1),_c('div',{staticClass:"flex-1"},[_vm._v(" "+_vm._s(_vm.getFullName(user))+" "),(!_vm.empty(user.appointment_counter))?_c('activix-tooltip',{attrs:{"content":_vm.$t('inturns.appointmentCounter')}},[_c('span',{staticClass:"cursor-default"},[_vm._v(" "+_vm._s('[' + user.appointment_counter + ']')+" ")])]):_vm._e(),_c('span',[_vm._v(" ("+_vm._s(_vm.formatDate(user.pivot.created_at))+") ")])],1),_c('div',{staticClass:"flex items-center text-lg -mx-1"},[(_vm.inTurn.account.in_turn_planned_meeting)?_c('div',{staticClass:"px-1"},[_c('activix-tooltip',{attrs:{"content":_vm.plannedMeetingTooltip(user)}},[_c('icon',{class:{
                                            opacity: !user.pivot.planned_meeting_date,
                                            disabled: _vm.plannedMeetingDisabled(user),
                                            'text-grey-600':
                                                !user.pivot.planned_meeting_date &&
                                                !_vm.authUser.isDirector() &&
                                                !_vm.authUser.isAdmin() &&
                                                !_vm.hasMeetingPlanned(user),
                                            'text-blue-500': user.pivot.planned_meeting_date,
                                        },attrs:{"name":"regular/calendar-3"},on:{"click":function($event){!_vm.plannedMeetingDisabled(user)
                                                ? _vm.updateStatus(index, user.id, 'planned_meeting_date')
                                                : ''}}})],1)],1):_vm._e(),_c('div',{staticClass:"px-1"},[_c('activix-tooltip',{attrs:{"content":_vm.$t('inturns.inBreak') +
                                            (!_vm.canEditStatus(_vm.authUser)
                                                ? '<br>' + _vm.$t('inturns.onlyDirectorCanEdit')
                                                : '')}},[_c('icon',{class:{
                                            'text-blue-500': user.pivot.break_date,
                                            opacity: !user.pivot.break_date,
                                            disabled:
                                                user.pivot.meeting_date ||
                                                user.pivot.delivery_date ||
                                                (user.pivot.planned_meeting_date &&
                                                    _vm.inTurn.account.in_turn_planned_meeting) ||
                                                (_vm.role == 'user' && user.id != _vm.authUser.id) ||
                                                !_vm.canEditStatus(_vm.authUser),
                                        },attrs:{"name":"regular/coffee-cup"},on:{"click":function($event){!(
                                                user.pivot.meeting_date ||
                                                user.pivot.delivery_date ||
                                                (_vm.role == 'user' && user.id != _vm.authUser.id)
                                            ) && _vm.canEditStatus(_vm.authUser)
                                                ? _vm.updateStatus(index, user.id, 'break_date')
                                                : ''}}})],1)],1),_c('div',{staticClass:"px-1"},[_c('activix-tooltip',{attrs:{"content":_vm.$t('inturns.inDelivery') +
                                            (!_vm.canEditStatus(_vm.authUser)
                                                ? '<br>' + _vm.$t('inturns.onlyDirectorCanEdit')
                                                : '')}},[_c('icon',{class:{
                                            'text-blue-500': user.pivot.delivery_date,
                                            opacity: !user.pivot.delivery_date,
                                            disabled:
                                                user.pivot.meeting_date ||
                                                (user.pivot.planned_meeting_date &&
                                                    _vm.inTurn.account.in_turn_planned_meeting) ||
                                                user.pivot.break_date ||
                                                (_vm.role == 'user' && user.id != _vm.authUser.id) ||
                                                !_vm.canEditStatus(_vm.authUser),
                                        },attrs:{"name":"regular/car-1"},on:{"click":function($event){!(
                                                user.pivot.meeting_date ||
                                                (user.pivot.planned_meeting_date &&
                                                    _vm.inTurn.account.in_turn_planned_meeting) ||
                                                user.pivot.break_date ||
                                                (_vm.role == 'user' && user.id != _vm.authUser.id)
                                            ) && _vm.canEditStatus(_vm.authUser)
                                                ? _vm.updateStatus(index, user.id, 'delivery_date')
                                                : ''}}})],1)],1),_c('div',{staticClass:"px-1"},[_c('activix-tooltip',{attrs:{"content":_vm.$t('inturns.inMeeting') +
                                            (!_vm.canEditStatus(_vm.authUser)
                                                ? '<br>' + _vm.$t('inturns.onlyDirectorCanEdit')
                                                : '')}},[_c('icon',{class:{
                                            'text-blue-500': user.pivot.meeting_date,
                                            opacity: !user.pivot.meeting_date,
                                            disabled:
                                                user.pivot.break_date ||
                                                user.pivot.delivery_date ||
                                                (user.pivot.planned_meeting_date &&
                                                    _vm.inTurn.account.in_turn_planned_meeting) ||
                                                (_vm.role == 'user' && user.id != _vm.authUser.id) ||
                                                !_vm.canEditStatus(_vm.authUser),
                                        },attrs:{"name":"regular/single-man-actions"},on:{"click":function($event){_vm.canUpdateMeetingDate(user)
                                                ? _vm.updateStatus(index, user.id, 'meeting_date')
                                                : ''}}})],1)],1),(_vm.role == 'admin' && _vm.inTurn.account.gun_miss)?_c('div',{staticClass:"px-1"},[_c('activix-tooltip',{attrs:{"content":_vm.$t('inturns.gunMiss') +
                                            (!_vm.empty(user.gun_miss_count) ? ' : ' + user.gun_miss_count : '')}},[_c('icon',{class:{
                                            'text-blue-500': user.gun_miss_count > 0,
                                            opacity: _vm.empty(user.gun_miss_count),
                                        },attrs:{"name":"regular/dislike-1"},on:{"click":function($event){return _vm.updateStatus(index, user.id, 'gunMiss')}}})],1)],1):_vm._e()])])}),0)],1)])]),_c('div',{staticClass:"col-xs-12 col-md-6 | flex"},[_c('div',{staticClass:"box | flex flex-col"},[_c('div',{staticClass:"box-header | border-b"},[_c('h4',{staticClass:"box-title"},[_vm._v(" "+_vm._s(_vm.$t('inturns.' + _vm.type + 'Advisor'))+" ")])]),_c('div',{staticClass:"box-body | flex-1"},[_c('draggable',{staticClass:"list sortable available-users | h-full",attrs:{"handle":".handle","options":{ group: _vm.type, handle: '.draggable' },"move":_vm.checkMoveFromAvailable},on:{"add":_vm.addedUserToAvailable,"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}},model:{value:(_vm.availableUsers),callback:function ($$v) {_vm.availableUsers=$$v},expression:"availableUsers"}},_vm._l((_vm.availableUsers),function(user){return _c('li',{key:user.id,staticClass:"flex items-center p-1 cursor-default",class:{
                            draggable: _vm.canDragUser(user),
                            'opacity-50': !_vm.canDragUser(user),
                        },attrs:{"id":user.id}},[_c('div',{staticClass:"p-2 flex items-center",class:{
                                'handle cursor-move': _vm.canDragUser(user),
                            }},[_c('icon',{staticClass:"text-grey-600",attrs:{"slot":"icon","name":"regular/navigation-menu"},slot:"icon"})],1),_vm._v(" "+_vm._s(_vm.getFullName(user))+" "),_c('activix-tooltip',{attrs:{"content":_vm.$t('inturns.appointmentCounter')}},[_c('span',{staticClass:"cursor-default"},[_vm._v(" "+_vm._s(user.appointment_counter != 0 ? '[' + user.appointment_counter + ']' : '')+" ")])])],1)}),0)],1)])]),_c('activix-confirm-modal',{attrs:{"type":"warning","title":_vm.$t('clientCard.cannotAddVehicleRenewal'),"content":_vm.confirm.updateLead.content,"approve-text":_vm.$t('clientCard.addLead'),"deny-text":_vm.$t('general.cancel'),"opened":_vm.confirm.updateLead.opened},on:{"update:opened":function($event){return _vm.$set(_vm.confirm.updateLead, "opened", $event)},"approve":_vm.triggerAddLead,"cancel":function($event){_vm.confirm.updateLead.content = null}}}),_c('activix-confirm-modal',{attrs:{"type":"warning","title":_vm.$t('clientCard.deleteVehicleConfirm'),"content":_vm.confirm.deleteLead.content,"approve-text":_vm.$t('clientCard.addLead'),"deny-text":_vm.$t('general.cancel'),"opened":_vm.confirm.deleteLead.opened},on:{"update:opened":function($event){return _vm.$set(_vm.confirm.deleteLead, "opened", $event)},"approve":_vm.triggerAddLead,"cancel":function($event){_vm.confirm.deleteLead.content = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }