<template>
    <div class="row | lg:flex mb-6">
        <div class="col-xs-12 col-md-6 | flex">
            <div class="box | flex flex-col">
                <div class="box-header | border-b flex items-center justify-between">
                    <h4 class="box-title">
                        {{ $t('inturns.' + type + 'Vehicle') }}
                    </h4>
                    <div class="flex items-center">
                        <activix-tooltip :content="$t('inturns.message_over_ten')" v-if="inTurn.users.length > 10">
                            <icon name="regular/information-circle" class="text-grey-500" />
                        </activix-tooltip>
                        <activix-tooltip
                            :content="$t('inturns.resetGunMiss')"
                            v-if="role == 'admin' && inTurn.account.gun_miss && inTurn.users.length > 0"
                        >
                            <icon name="regular/dislike-1" class="link-grey-light text-lg ml-2" @click="resetGunMiss" />
                        </activix-tooltip>
                    </div>
                </div>
                <div class="box-body | flex-1">
                    <draggable
                        class="list sortable scheduled-users | h-full"
                        handle=".handle"
                        :options="{ group: type, draggable: '.draggable' }"
                        @add="addedUserToSchedule"
                        @update="updateUserPosition"
                        @start="drag = true"
                        @end="drag = false"
                        v-model="inTurnUsers"
                    >
                        <li
                            class="flex items-center pl-1 py-0 cursor-default"
                            :class="{
                                draggable:
                                    role == 'admin' ||
                                    (user.id == authUser.id && !userDisabled(user)) ||
                                    authUser.in_turn_edit,
                                'opacity-50':
                                    role != 'admin' &&
                                    (user.id != authUser.id || userDisabled(user)) &&
                                    !authUser.in_turn_edit,
                            }"
                            :key="user.id"
                            v-for="(user, index) in inTurnUsers"
                        >
                            <div class="handle | p-2 cursor-move flex items-center">
                                <icon class="text-grey-600" name="regular/navigation-menu" slot="icon" />
                            </div>

                            <div class="flex-1">
                                {{ getFullName(user) }}

                                <activix-tooltip
                                    :content="$t('inturns.appointmentCounter')"
                                    v-if="!empty(user.appointment_counter)"
                                >
                                    <span class="cursor-default">
                                        {{ '[' + user.appointment_counter + ']' }}
                                    </span>
                                </activix-tooltip>

                                <span> ({{ formatDate(user.pivot.created_at) }}) </span>
                            </div>

                            <div class="flex items-center text-lg -mx-1">
                                <div class="px-1" v-if="inTurn.account.in_turn_planned_meeting">
                                    <activix-tooltip :content="plannedMeetingTooltip(user)">
                                        <icon
                                            name="regular/calendar-3"
                                            :class="{
                                                opacity: !user.pivot.planned_meeting_date,
                                                disabled: plannedMeetingDisabled(user),
                                                'text-grey-600':
                                                    !user.pivot.planned_meeting_date &&
                                                    !authUser.isDirector() &&
                                                    !authUser.isAdmin() &&
                                                    !hasMeetingPlanned(user),
                                                'text-blue-500': user.pivot.planned_meeting_date,
                                            }"
                                            @click="
                                                !plannedMeetingDisabled(user)
                                                    ? updateStatus(index, user.id, 'planned_meeting_date')
                                                    : ''
                                            "
                                        />
                                    </activix-tooltip>
                                </div>
                                <div class="px-1">
                                    <activix-tooltip
                                        :content="
                                            $t('inturns.inBreak') +
                                                (!canEditStatus(authUser)
                                                    ? '<br>' + $t('inturns.onlyDirectorCanEdit')
                                                    : '')
                                        "
                                    >
                                        <icon
                                            name="regular/coffee-cup"
                                            :class="{
                                                'text-blue-500': user.pivot.break_date,
                                                opacity: !user.pivot.break_date,
                                                disabled:
                                                    user.pivot.meeting_date ||
                                                    user.pivot.delivery_date ||
                                                    (user.pivot.planned_meeting_date &&
                                                        inTurn.account.in_turn_planned_meeting) ||
                                                    (role == 'user' && user.id != authUser.id) ||
                                                    !canEditStatus(authUser),
                                            }"
                                            @click="
                                                !(
                                                    user.pivot.meeting_date ||
                                                    user.pivot.delivery_date ||
                                                    (role == 'user' && user.id != authUser.id)
                                                ) && canEditStatus(authUser)
                                                    ? updateStatus(index, user.id, 'break_date')
                                                    : ''
                                            "
                                        />
                                    </activix-tooltip>
                                </div>
                                <div class="px-1">
                                    <activix-tooltip
                                        :content="
                                            $t('inturns.inDelivery') +
                                                (!canEditStatus(authUser)
                                                    ? '<br>' + $t('inturns.onlyDirectorCanEdit')
                                                    : '')
                                        "
                                    >
                                        <icon
                                            name="regular/car-1"
                                            :class="{
                                                'text-blue-500': user.pivot.delivery_date,
                                                opacity: !user.pivot.delivery_date,
                                                disabled:
                                                    user.pivot.meeting_date ||
                                                    (user.pivot.planned_meeting_date &&
                                                        inTurn.account.in_turn_planned_meeting) ||
                                                    user.pivot.break_date ||
                                                    (role == 'user' && user.id != authUser.id) ||
                                                    !canEditStatus(authUser),
                                            }"
                                            @click="
                                                !(
                                                    user.pivot.meeting_date ||
                                                    (user.pivot.planned_meeting_date &&
                                                        inTurn.account.in_turn_planned_meeting) ||
                                                    user.pivot.break_date ||
                                                    (role == 'user' && user.id != authUser.id)
                                                ) && canEditStatus(authUser)
                                                    ? updateStatus(index, user.id, 'delivery_date')
                                                    : ''
                                            "
                                        />
                                    </activix-tooltip>
                                </div>
                                <div class="px-1">
                                    <activix-tooltip
                                        :content="
                                            $t('inturns.inMeeting') +
                                                (!canEditStatus(authUser)
                                                    ? '<br>' + $t('inturns.onlyDirectorCanEdit')
                                                    : '')
                                        "
                                    >
                                        <icon
                                            name="regular/single-man-actions"
                                            :class="{
                                                'text-blue-500': user.pivot.meeting_date,
                                                opacity: !user.pivot.meeting_date,
                                                disabled:
                                                    user.pivot.break_date ||
                                                    user.pivot.delivery_date ||
                                                    (user.pivot.planned_meeting_date &&
                                                        inTurn.account.in_turn_planned_meeting) ||
                                                    (role == 'user' && user.id != authUser.id) ||
                                                    !canEditStatus(authUser),
                                            }"
                                            @click="
                                                canUpdateMeetingDate(user)
                                                    ? updateStatus(index, user.id, 'meeting_date')
                                                    : ''
                                            "
                                        />
                                    </activix-tooltip>
                                </div>
                                <div class="px-1" v-if="role == 'admin' && inTurn.account.gun_miss">
                                    <activix-tooltip
                                        :content="
                                            $t('inturns.gunMiss') +
                                                (!empty(user.gun_miss_count) ? ' : ' + user.gun_miss_count : '')
                                        "
                                    >
                                        <icon
                                            name="regular/dislike-1"
                                            :class="{
                                                'text-blue-500': user.gun_miss_count > 0,
                                                opacity: empty(user.gun_miss_count),
                                            }"
                                            @click="updateStatus(index, user.id, 'gunMiss')"
                                        />
                                    </activix-tooltip>
                                </div>
                            </div>
                        </li>
                    </draggable>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-md-6 | flex">
            <div class="box | flex flex-col">
                <div class="box-header | border-b">
                    <h4 class="box-title">
                        {{ $t('inturns.' + type + 'Advisor') }}
                    </h4>
                </div>
                <div class="box-body | flex-1">
                    <draggable
                        class="list sortable available-users | h-full"
                        handle=".handle"
                        :options="{ group: type, handle: '.draggable' }"
                        :move="checkMoveFromAvailable"
                        @add="addedUserToAvailable"
                        @start="drag = true"
                        @end="drag = false"
                        v-model="availableUsers"
                    >
                        <li
                            :id="user.id"
                            class="flex items-center p-1 cursor-default"
                            :class="{
                                draggable: canDragUser(user),
                                'opacity-50': !canDragUser(user),
                            }"
                            :key="user.id"
                            v-for="user in availableUsers"
                        >
                            <div
                                class="p-2 flex items-center"
                                :class="{
                                    'handle cursor-move': canDragUser(user),
                                }"
                            >
                                <icon class="text-grey-600" name="regular/navigation-menu" slot="icon" />
                            </div>

                            {{ getFullName(user) }}

                            <activix-tooltip :content="$t('inturns.appointmentCounter')">
                                <span class="cursor-default">
                                    {{ user.appointment_counter != 0 ? '[' + user.appointment_counter + ']' : '' }}
                                </span>
                            </activix-tooltip>
                        </li>
                    </draggable>
                </div>
            </div>
        </div>

        <activix-confirm-modal
            type="warning"
            :title="$t('clientCard.cannotAddVehicleRenewal')"
            :content="confirm.updateLead.content"
            :approve-text="$t('clientCard.addLead')"
            :deny-text="$t('general.cancel')"
            :opened.sync="confirm.updateLead.opened"
            @approve="triggerAddLead"
            @cancel="confirm.updateLead.content = null"
        />

        <activix-confirm-modal
            type="warning"
            :title="$t('clientCard.deleteVehicleConfirm')"
            :content="confirm.deleteLead.content"
            :approve-text="$t('clientCard.addLead')"
            :deny-text="$t('general.cancel')"
            :opened.sync="confirm.deleteLead.opened"
            @approve="triggerAddLead"
            @cancel="confirm.deleteLead.content = null"
        />
    </div>
</template>

<script>
    /* eslint-disable vue/no-mutating-props */
    import draggable from 'vuedraggable';
    import { mapState } from 'pinia';
    import { formater } from '../../globals/LeadFormatter.js';

    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            draggable,
        },

        props: {
            users: {
                type: Array,
                default: () => [],
            },
            type: {
                type: String,
                default: '',
            },
            inTurn: {
                type: Object,
                default: () => {},
            },
            pageId: {
                type: Number,
                default: 0,
            },
        },

        data() {
            return {
                currentMoment: now().toString(),
                availableUsers: [],
                inTurnUsers: [],
                confirm: {
                    updateLead: {
                        opened: false,
                        content: null,
                    },
                    deleteLead: {
                        opened: false,
                        content: null,
                    },
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            role() {
                if (this.authUser.isAdmin() || this.authUser.in_turn_edit) {
                    return 'admin';
                }
                return 'user';
            },
        },

        watch: {
            'inTurn.users'(value) {
                this.inTurnUsers = value;
            },

            users() {
                this.setAvailableUsers();
            },
        },

        methods: {
            plannedMeetingDisabled(user) {
                if (
                    user.pivot.meeting_date ||
                    user.pivot.delivery_date ||
                    user.pivot.break_date ||
                    (this.role == 'user' && user.id != this.authUser.id) ||
                    (!this.authUser.isDirector() && !this.authUser.isAdmin() && !this.hasMeetingPlanned(user))
                ) {
                    return true;
                }

                return false;
            },

            plannedMeetingTooltip(user) {
                if (!this.authUser.isDirector() && !this.authUser.isAdmin() && !this.hasMeetingPlanned(user)) {
                    return this.$t('inturns.noPlannedMeeting');
                }

                return this.$t('inturns.inPlannedMeeting');
            },

            canUpdateMeetingDate(user) {
                const can =
                    !(
                        user.pivot.break_date ||
                        user.pivot.delivery_date ||
                        (user.pivot.planned_meeting_date && this.inTurn.account.in_turn_planned_meeting) ||
                        (this.role == 'user' && user.id != this.authUser.id)
                    ) && this.canEditStatus(this.authUser);
                return can;
            },

            updateUserPosition(evt) {
                const user = this.inTurnUsers[evt.newIndex];
                this.updatePosition(evt.newIndex + 1, evt.oldIndex + 1, user.id);
            },

            checkMoveFromAvailable(evt) {
                const user = this.users.find(user => {
                    return user.id == evt.draggedContext.element.id;
                });

                if (!user) {
                    return false;
                }

                evt.draggedContext.element.today_appointments = user.today_appointments;

                evt.draggedContext.element.pivot = {
                    break_date: null,
                    delivery_date: null,
                    meeting_date: null,
                    planned_meeting_date: null,
                    position: this.inTurnUsers.length,
                    user_id: evt.draggedContext.element.id,
                    in_turn_id: this.inTurn.id,
                };

                if (this.role != 'admin') {
                    evt.futureIndex = this.inTurnUsers.length;
                }

                return true;
            },

            addedUserToAvailable(evt) {
                const user = this.availableUsers[evt.newIndex];
                this.deleteStatus(user.id);
                return true;
            },

            addedUserToSchedule(evt) {
                const user = this.inTurnUsers[evt.newIndex];
                this.syncUser(user.id, evt.newIndex);
            },

            setInTurnUsers() {
                this.inTurnUsers = this.inTurn.users;
            },

            setAvailableUsers() {
                const tempUsers = [];

                for (let i = 0; i < this.users.length; i++) {
                    if (
                        this.type == 'financial' &&
                        this.users[i].role.name == 'commercial' &&
                        !this.containsUser(this.users[i].id)
                    ) {
                        tempUsers.push(this.users[i]);
                    } else if (
                        this.type == 'deskManager' &&
                        this.users[i].role.name == 'director' &&
                        !this.containsUser(this.users[i].id)
                    ) {
                        tempUsers.push(this.users[i]);
                    } else {
                        $.each(this.users[i].divisions, (index, value) => {
                            if (
                                this.type != 'financial' &&
                                this.users[i].role.name != 'commercial' &&
                                value.name == this.type &&
                                !this.containsUser(this.users[i].id)
                            ) {
                                tempUsers.push(this.users[i]);
                            }
                        });
                    }
                }

                this.availableUsers = tempUsers;
            },

            formatDate(date) {
                date = locale_dt(date);

                if (!date.isValid()) {
                    date = now();
                }

                return `${date.toTimeShortString()}`;
            },

            canEditStatus() {
                return (
                    this.authUser.isDirector() ||
                    this.authUser.isAdmin() ||
                    !this.authUser.account.in_turn_director_management
                );
            },

            hasMeetingPlanned(user) {
                if (user.pivot.planned_meeting_date) {
                    return true;
                }

                if (!user.today_appointments || user.today_appointments.length == 0) {
                    return false;
                }

                for (let i = user.today_appointments.length - 1; i >= 0; i--) {
                    if (
                        as_locale(user.today_appointments[i].start_at, 'start_at').isBetween(
                            now().subHours(4),
                            now().addHours(4),
                        )
                    ) {
                        return true;
                    }
                }

                return false;
            },

            userDisabled(user) {
                const meetingDate = as_locale(user.pivot.meeting_date, 'meeting_date');

                return (
                    user.pivot.locked &&
                    user.id == this.authUser.id &&
                    meetingDate.isValid() &&
                    locale_dt(this.currentMoment).diff(meetingDate, 'seconds') > 120
                );
            },

            containsUser(id) {
                for (let i = 0; i < this.inTurn.users.length; i++) {
                    if (this.inTurn.users[i].id === id) {
                        return true;
                    }
                }

                return false;
            },

            addUserSorting(newIndex, oldIndex) {
                for (let i = newIndex; i < oldIndex; i++) {
                    this.inTurn.users[i].pivot.position = i + 1;
                }

                this.inTurn.users[oldIndex].pivot.position = newIndex;
            },

            async updateStatus(index, userId, type) {
                if (
                    (type == 'break_date' &&
                        !this.inTurnUsers[index].pivot.meeting_date &&
                        !this.inTurnUsers[index].pivot.delivery_date &&
                        (!this.inTurnUsers[index].pivot.planned_meeting_date ||
                            !this.inTurn.account.in_turn_planned_meeting)) ||
                    (type == 'meeting_date' &&
                        !this.inTurnUsers[index].pivot.break_date &&
                        !this.inTurnUsers[index].pivot.delivery_date &&
                        (!this.inTurnUsers[index].pivot.planned_meeting_date ||
                            !this.inTurn.account.in_turn_planned_meeting)) ||
                    (type == 'delivery_date' &&
                        !this.inTurnUsers[index].pivot.meeting_date &&
                        !this.inTurnUsers[index].pivot.break_date &&
                        (!this.inTurnUsers[index].pivot.planned_meeting_date ||
                            !this.inTurn.account.in_turn_planned_meeting)) ||
                    (type == 'planned_meeting_date' &&
                        this.inTurn.account.in_turn_planned_meeting &&
                        !this.inTurnUsers[index].pivot.break_date &&
                        !this.inTurnUsers[index].pivot.delivery_date &&
                        !this.inTurnUsers[index].pivot.meeting_date) ||
                    (type == 'gunMiss' &&
                        this.inTurn.account.gun_miss &&
                        (this.role == 'admin' || (this.role == 'user' && this.authUser.id == userId)))
                ) {
                    $('.tooltip').hide();

                    const dataUpdate = {
                        userId,
                        update: 'status',
                        type,
                        date: this.inTurnUsers[index].pivot[type] ? null : now().toString(),
                        inTurnId: this.inTurn.id,
                        pageId: this.pageId,
                    };

                    try {
                        const response = await this.$axios.put(`v1/in-turn/${this.inTurn.id}`, dataUpdate);

                        if (response.data.message == 'needUpdateInLead') {
                            let leadsString = '';

                            if (response.data.leads.length > 0) {
                                leadsString += `
                                    <hr />
                                    ${this.$t('inturns.relevantLeads')}
                                    <table class="col-sm-8 col-sm-offset-2 | my-4 text-center">
                                `;

                                for (let i = response.data.leads.length - 1; i >= 0; i--) {
                                    const route = this.$router.resolve({
                                        name: 'leads.update',
                                        params: { id: response.data.leads[i].id },
                                    });

                                    leadsString += `
                                        <tr>
                                            <td>
                                                <a href="${route.href}" target="_blank">
                                                    ${this.getFullName(response.data.leads[i])}
                                                </a>
                                            </td>
                                            <td>
                                                ${formater.formatVehicleWanted(response.data.leads[i])}
                                            </td>
                                        </tr>
                                    `;
                                }

                                leadsString += '</table><br><br>';
                            }

                            this.confirm.updateLead.content = this.$t(`error.${response.data.message}`) + leadsString;
                            this.confirm.updateLead.opened = true;
                        }

                        if (!this.inTurnUsers[index].pivot[type]) {
                            this.inTurnUsers[index].pivot[type] = now().toString();
                        } else {
                            this.inTurnUsers[index].pivot[type] = null;
                        }

                        this.$emit('fetch-in-turns');
                    } catch (error) {
                        this.$notify.error(this.$t('inturns.alerts.update.error'));
                    }
                }
            },

            async deleteStatus(userId) {
                const dataUpdate = {
                    userId,
                    update: 'delete',
                    pageId: this.pageId,
                };

                try {
                    const response = await this.$axios.put(`v1/in-turn/${this.inTurn.id}`, dataUpdate);

                    if (response.data.success) {
                        if (response.data.message == 'needUpdateInLead') {
                            let leadsString = '';

                            if (response.data.leads.length > 0) {
                                leadsString += `
                                    <hr />
                                    ${this.$t('inturns.relevantLeads')}
                                    <table class="col-sm-8 col-sm-offset-2 | my-4 text-center">
                                `;

                                for (let i = response.data.leads.length - 1; i >= 0; i--) {
                                    const route = this.$router.resolve({
                                        name: 'leads.update',
                                        params: { id: response.data.leads[i].id },
                                    });

                                    leadsString += `
                                        <tr>
                                            <td>
                                                <a href="${route.href}" target="_blank">
                                                    ${this.getFullName(response.data.leads[i])}
                                                </a>
                                            </td>
                                            <td>
                                                ${formater.formatVehicleWanted(response.data.leads[i])}
                                            </td>
                                        </tr>
                                    `;
                                }

                                leadsString += '</table><br><br>';
                            }

                            this.confirm.deleteLead.content = this.$t(`error.${response.data.message}`) + leadsString;
                            this.confirm.deleteLead.opened = true;
                        }
                    }

                    this.$emit('fetch-in-turns');
                } catch (error) {
                    this.$notify.error(this.$t('inturns.alerts.destroy.error'));
                }
            },

            async syncUser(userId, position) {
                const dataUpdate = {
                    userId,
                    position: position + 1,
                    update: 'sync',
                    pageId: this.pageId,
                };

                await this.$axios.put(`v1/in-turn/${this.inTurn.id}`, dataUpdate);
            },

            async updatePosition(newPosition, oldPosition, userId) {
                if (this.role == 'admin') {
                    const dataUpdate = {
                        newPosition,
                        oldPosition,
                        userId,
                        update: 'updatePosition',
                        pageId: this.pageId,
                    };

                    await this.$axios.put(`v1/in-turn/${this.inTurn.id}`, dataUpdate);
                }
            },

            async resetGunMiss() {
                if (this.role == 'admin' && this.inTurn.account.gun_miss) {
                    const dataUpdate = {
                        accountId: this.inTurn.account.id,
                    };

                    try {
                        const response = await this.$axios.put('v1/in-turn/gun-miss-reset', dataUpdate);
                        if (response.data.success) {
                            for (let i = 0; i < this.inTurn.users.length; i++) {
                                this.inTurn.users[i].gun_miss_count = 0;
                            }
                        }
                    } catch (error) {
                        this.$notify.error(this.$t('intruns.alerts.gunmiss.error'));
                    }
                }
            },

            updateCurrentMoment() {
                this.currentMoment = now().toString();

                setTimeout(() => {
                    this.updateCurrentMoment({ property: 'min-height' });
                }, 2000);
            },

            triggerAddLead() {
                this.$eventBus.$emit('open-lead-create', {
                    accountId: this.authUser.account.id,
                    type: 'walk-in',
                });
            },

            canDragUser(user) {
                return this.role == 'admin' || user.id == this.authUser.id || this.authUser.in_turn_edit;
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.updateCurrentMoment();
                this.setAvailableUsers();
                this.setInTurnUsers();
            });
        },
    };
</script>
